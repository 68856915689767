.router {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  overflow-y: auto;
  background-color: $ch-color-bg-body;
  overflow-x: hidden;
}

.content {
  flex-grow: 1;
}

.main {
  display: flex;
  flex-direction: row;
  flex: auto;
  justify-content: center;
}

.column-center-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.container {
  @include container;
}
