@import "../../../utils";

@mixin global-heading-settings {
  margin: 0;
  padding: 0;
  color: $ch-color-black;
  line-height: $ch-lineheight-header;
}

.h1 {
  @include global-heading-settings;
  @include font-gilroy-black;
  @include set-breakpoint-fontsize($ch-font-h1);
}

.h2 {
  @include global-heading-settings;
  @include font-gilroy-black;
  @include set-breakpoint-fontsize($ch-font-h2);
}

.h3 {
  @include global-heading-settings;
  @include font-gilroy-semibold;
  @include set-breakpoint-fontsize($ch-font-h3);
}

.h4 {
  @include global-heading-settings;
  @include font-gilroy-semibold;
  @include set-breakpoint-fontsize($ch-font-h4);
}
