:root {
  @include font-gilroy-regular;
  // @include font-miriam-regular; - was used on MyCareer
  font-size: $ch-fontsize;
  color: $ch-color-dark1;
}

a {
  font-size: inherit;
  line-height: inherit;
  word-break: break-word;
  color: $ch-color-primary;

  &:hover {
    color: $ch-color-primary-hover;
  }

  &:active {
    color: $ch-color-primary-pressed;
  }
}
