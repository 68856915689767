@import "common/utils";
@import "./Button.module.mixins";
// used separate file to enable autoformatting
@import "./Button.module.colors";
@import "./Button.module.types";

// custom values to align sizes with design
$button-padding-big: 22px;
$button-padding-regular: 18px;
$button-padding-small: 10px;

.button {
  @include button-reset;
}

// types
.default,
.primary,
.secondary {
  @include button-general;
}
.default {
  @include button-type-ghost;
} // ghost
.primary {
  @include button-type-primary(
    $ch-color-primary,
    $ch-color-primary-hover,
    $ch-color-primary-pressed
  );
}
.secondary {
  @include button-type-secondary(
    $ch-color-primary,
    $ch-color-primary-hover,
    $ch-color-primary-pressed
  );
}

// sizes
.regular {
  @include set-breakpoint-fontsize($ch-font-button);
  @include button-size-long(410px);
  padding: ($button-padding-regular + 2px) $button-padding-regular * 2
    ($button-padding-regular - 2px);
  min-width: 150px;
}
.small {
  @include set-breakpoint-fontsize($ch-font-button_small);
  @include button-size-long(150px);
  padding: ($button-padding-small + 1px) $button-padding-small * 2
    ($button-padding-small - 1px);
  min-width: 100px;
  box-shadow: none;
}
.big {
  @include set-breakpoint-fontsize($ch-font-button_big);
  @include button-size-long(700px);
  border-radius: $ch-border-radius-buttons-large;
  padding: ($button-padding-big + 3px) $button-padding-big * 2
    ($button-padding-big - 3px);
  min-width: 200px;
}

// secondary types
.link {
  @include button-type-link;
}

.brick {
  @include button-reset;
  @include button-general;
  @include button-type-ghost;
  @include button-type-brick;
}
