@font-face {
  font-family: "Gilroy";
  src: url("./Gilroy-SemiBold.eot");
  src: local("Gilroy SemiBold"), local("Gilroy-SemiBold"),
    url("./Gilroy-SemiBold.eot?#iefix") format("embedded-opentype"),
    url("./Gilroy-SemiBold.woff") format("woff"),
    url("./Gilroy-SemiBold.ttf") format("truetype");

  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Gilroy";
  src: url("./Gilroy-Regular.eot");
  src: local("Gilroy Regular"), local("Gilroy-Regular"),
    url("./Gilroy-Regular.eot?#iefix") format("embedded-opentype"),
    url("./Gilroy-Regular.woff") format("woff"),
    url("./Gilroy-Regular.ttf") format("truetype");

  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Gilroy";

  src: url("./Gilroy-Bold.eot");
  src: local("Gilroy Bold"), local("Gilroy-Bold"),
    url("./Gilroy-Bold.eot?#iefix") format("embedded-opentype"),
    url("./Gilroy-Bold.woff") format("woff"),
    url("./Gilroy-Bold.ttf") format("truetype");

  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Gilroy";
  src: url("./Gilroy-Black.eot");
  src: local("Gilroy Black"), local("Gilroy-Black"),
    url("./Gilroy-Black.eot?#iefix") format("embedded-opentype"),
    url("./Gilroy-Black.woff") format("woff"),
    url("./Gilroy-Black.ttf") format("truetype");

  font-weight: 900;
  font-style: normal;
  font-display: swap;
}
