@import "../../utils";

.footer {
  background-color: transparent;
  color: $ch-color-black;
  width: 100%;

  .content {
    padding: $ch-space-horizontal_gap-mobile / 2 0;
    @include mq(tablet) {
      padding: $ch-space-horizontal_gap / 2 0;
    }
  }

  .menu {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: $space-l;

    .link {
      @include font-gilroy-regular;
      @include set-breakpoint-fontsize($ch-font-p_small);
      text-align: center;
      padding: $space-xs;
      margin: 0 $space-s;
      text-decoration: none;
      color: $ch-color-dark1;

      &:hover {
        border-color: $ch-color-black;
        color: $ch-color-black;
      }
      &:active {
        border-color: $ch-color-black-pressed;
        color: $ch-color-black-pressed;
      }
    }

    @include mq(mobile, max) {
      flex-direction: column;
      margin-bottom: $space-xl;
      .link {
        margin-top: $space-xs;
        margin-bottom: $space-xs;
      }
    }

    @include mq(tablet) {
      flex-wrap: wrap;

      .link {
        display: block;
        margin: $space-s;
      }
    }
  }

  .text {
    @include font-gilroy-regular;
    line-height: 1.6;
    text-align: center;
    color: $ch-color-dark1;
  }
}
