$space-xxxl: 48px;
$space-xxl: 32px;
$space-xl: 24px;
$space-l: 16px;
$space-m: 12px;
$space-s: 8px;
$space-xs: 4px;

$ch-space-grid_gap: 40px;
$ch-space-grid_gap-mobile: 25px;
$ch-space-grid_gap-large: $ch-space-grid_gap * 1.5;

$ch-space-horizontal_gap: 100px;
$ch-space-horizontal_gap-mobile: 80px;
