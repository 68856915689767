.default {
  &.green {
    @include button-type-ghost($ch-color-primary);
  }
  &.black {
    @include button-type-ghost($ch-color-black);
  }
  &.white {
    @include button-type-ghost($ch-color-white);
  }
  &.grey {
    @include button-type-ghost($ch-color-grey);
  }
  &.blue {
    @include button-type-ghost($ch-color-blue);
  }
  &.red {
    @include button-type-ghost($ch-color-red);
  }
} // ghost
.primary {
  &.green {
    @include button-type-primary($ch-color-primary);
  }
  &.black {
    @include button-type-primary($ch-color-black);
  }
  &.grey {
    @include button-type-primary($ch-color-grey);
  }
  &.blue {
    @include button-type-primary($ch-color-blue);
  }
  &.red {
    @include button-type-primary($ch-color-red);
  }
  &.white {
    @include button-type-secondary(
      $ch-color-black
    ); //because it looks like secondary but with white border
    border-color: $ch-color-white;
    &:hover {
      border-color: $ch-color-white-hover;
    }
    &:active {
      border-color: $ch-color-white-pressed;
    }
    &.disabled {
      border-color: $ch-color-white-pressed;
    }
  }
}
.secondary {
  &.green {
    @include button-type-secondary($ch-color-primary);
  }
  &.black {
    @include button-type-secondary($ch-color-black);
  }
  &.grey {
    @include button-type-secondary($ch-color-grey);
  }
  &.blue {
    @include button-type-secondary($ch-color-blue);
  }
  &.red {
    @include button-type-secondary($ch-color-red);
  }
  &.white {
    @include button-type-secondary($ch-color-white);
    background-color: transparent;
    &:hover {
      background-color: rgba($ch-color-white, 0.2);
    }
    &:active {
      background-color: rgba($ch-color-white, 0.3);
    }
    &.disabled {
      background-color: rgba($ch-color-white, 0.1);
    }
  }
}
