@mixin button-general {
  @include font-gilroy-semibold;
  border-radius: $ch-border-radius-buttons;
  border: 1px solid transparent;
}

@mixin button-reset {
  background: none;
  outline: none;
  border: none;
  margin: 0;
  padding: 0;
  cursor: pointer;
  line-height: 1.15; // left as fallback
  // to set buttons' height to exactly *0px range according to design:
  line-height: calc(1em + 2px);
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  text-align: center;
  text-transform: none;
  transition: $ch-transition-fast;
  font-size: inherit;
  font-family: inherit;
}

@mixin button-size-long($limit) {
  &.long {
    width: 100%;
    max-width: $limit;
  }
}

@mixin button-type-ghost(
  $color-static: $ch-color-white,
  $color-hover: rgba($color-static, 0.3),
  $color-pressed: rgba($color-static, 0.4)
) {
  border-color: transparent;
  background-color: rgba($color-static, 0.2);
  color: $ch-color-white;
  -webkit-text-fill-color: $ch-color-white;
  &:hover {
    border-color: transparent;
    background-color: $color-hover;
    color: $ch-color-white;
    -webkit-text-fill-color: $ch-color-white;
  }
  &:active {
    border-color: transparent;
    background-color: $color-pressed;
    color: $ch-color-white;
    -webkit-text-fill-color: $ch-color-white;
  }
  &.disabled {
    border-color: transparent;
    background-color: rgba($color-static, 0.2);
    color: $ch-color-grey;
    -webkit-text-fill-color: $ch-color-grey;
  }
}
@mixin button-type-primary(
  $color-static: $ch-color-primary,
  $color-hover: lighten($color-static, 2%),
  $color-pressed: darken($color-static, 2%)
) {
  border-color: $color-static;
  background-color: $color-static;
  color: $ch-color-white;
  -webkit-text-fill-color: $ch-color-white;
  box-shadow: 0 1em 1.5em 0 $ch-opacity-black-008;

  &:hover {
    border-color: $color-static;
    background-color: $color-hover;
    color: $ch-color-white;
    -webkit-text-fill-color: $ch-color-white;
  }
  &:active {
    border-color: $color-static;
    background-color: $color-pressed;
    color: $ch-color-white;
    -webkit-text-fill-color: $ch-color-white;
  }
  &.disabled {
    border-color: $ch-color-light3;
    background-color: $ch-color-light3;
    color: $ch-color-grey;
    -webkit-text-fill-color: $ch-color-grey;
  }
}
@mixin button-type-secondary(
  $color-static: $ch-color-primary,
  $color-hover: lighten($color-static, 2%),
  $color-pressed: darken($color-static, 2%)
) {
  border-color: $color-static;
  background-color: $ch-color-white;
  color: $color-static;
  -webkit-text-fill-color: $color-static;

  &:hover {
    border-color: $color-hover;
    background-color: $ch-color-white-hover;
    color: $color-hover;
    -webkit-text-fill-color: $color-hover;
  }
  &:active {
    border-color: $color-pressed;
    background-color: $ch-color-white-pressed;
    color: $color-pressed;
    -webkit-text-fill-color: $color-pressed;
  }
  &.disabled {
    border-color: $ch-color-grey;
    background-color: $ch-color-light1;
    color: $ch-color-grey;
    -webkit-text-fill-color: $ch-color-grey;
  }
}
