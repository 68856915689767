// will add type to mixins after agreement on the design (CareerHub process)

@mixin button-type-link() {
  @include font-gilroy-regular;
  padding: 0;
  border: none;
  justify-content: start;
  color: $ch-color-dark1;
  text-align: left;
  text-transform: none;

  &.small {
    @include set-breakpoint-fontsize($ch-font-p_small);
  }

  &:hover {
    color: $ch-color-black;
  }
  &:active {
    color: $ch-color-black-pressed;
  }

  &.green {
    @include font-gilroy-regular;
    color: $ch-color-primary;
    text-align: left;
    text-transform: none;

    &:hover {
      color: $ch-color-primary-hover;
    }
    &:active {
      color: $ch-color-primary-pressed;
    }
  }

  &.blue {
    @include font-gilroy-regular;
    color: $ch-color-blue;
    text-align: left;
    text-transform: none;

    &:hover {
      color: $ch-color-blue-hover;
    }
    &:active {
      color: $ch-color-blue-pressed;
    }
  }

  &.disabled,
  &.green.disabled {
    color: $ch-color-grey;
  }
}

@mixin button-type-brick() {
  $padY: 6px;
  $padX: 12px;
  $arrSpace: 15px;

  padding: ($padY + 1px) ($padX + $arrSpace) ($padY - 1px) $padX;
  min-width: unset;
  text-align: left;
  border-radius: $ch-border-radius-large;
  @include set-breakpoint-fontsize($ch-font-button_small);

  &.rtl {
    text-align: right;
    padding: ($padY + 1px) $padX ($padY - 1px) ($padX + $arrSpace);
    &:after,
    &:before {
      right: unset;
      left: $padY + ($arrSpace / 2);
    }
    &:after {
      transform: translate(0, -50%) rotate(225deg);
    }

    &:hover {
      &:after,
      &:before {
        right: unset;
        left: $padY + ($arrSpace / 2) - 3px;
      }
    }
  }

  // arrow
  position: relative;
  &:after,
  &:before {
    content: "";
    right: $padY + ($arrSpace / 2);
    position: absolute;
    top: 50%;
    transition: $ch-transition-norm;
  }
  &:after {
    width: 6px;
    height: 6px;
    border-right: 2px solid white;
    border-top: 2px solid white;
    transform: translate(0, -50%) rotate(45deg);
  }
  &:before {
    width: 9px;
    height: 2px;
    background: white;
    transform: translate(0, -50%);
    opacity: 0;
  }

  &:hover {
    &:after,
    &:before {
      right: $padY + ($arrSpace / 2) - 3px;
    }
    &:before {
      opacity: 1;
    }
  }
}
