$ch-border-radius: 8px;
$ch-border-radius-large: 16px;

$ch-border-radius-buttons: 30px;
$ch-border-radius-buttons-large: 35px;

$ch-border: 1px solid $ch-color-light2;

$ch-border-white: 2px solid $ch-color-white;
$ch-border-dark-grey: 2px solid $ch-color-dark1;
$ch-border-primary: 2px solid $ch-color-primary;

$ch-border-blue: 2px solid rgba(0, 191, 223, 0.4);
$ch-border-blue-dashed: 2px dashed rgba(0, 191, 223, 0.4);

$ch-border-grey: 2px solid $ch-color-grey;
$ch-border-grey-narrow: 1px solid $ch-color-grey;
$ch-border-grey-dashed: 2px dashed $ch-color-grey;
