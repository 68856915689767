// mixins to include font to element

/* headers */

@mixin font-gilroy-regular() {
  font-family: "Gilroy";
  font-weight: normal;
  font-style: normal;
}

@mixin font-gilroy-semibold() {
  font-family: "Gilroy";
  font-weight: 600;
  font-style: normal;
}

@mixin font-gilroy-bold() {
  font-family: "Gilroy", sans-serif;
  font-weight: bold;
  font-style: normal;
}

@mixin font-gilroy-black() {
  font-family: "Gilroy", sans-serif;
  font-weight: 900;
  font-style: normal;
}

/* content */
@mixin font-miriam-regular() {
  font-family: "Miriam Libre", sans-serif;
  font-weight: normal;
  font-style: normal;
}

@mixin font-miriam-bold() {
  font-family: "Miriam Libre", sans-serif;
  font-weight: 600;
  font-style: normal;
}
