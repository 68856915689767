@import "../../../utils";

.default {
  @include font-gilroy-regular;
  @include set-breakpoint-fontsize($ch-font-p_small);
  word-break: break-word;
  color: $ch-color-dark1;
}

.top {
  text-transform: uppercase;
  margin-bottom: $space-xs;
}

.active {
  cursor: pointer;
  color: $ch-color-black;
}

.bottom {
  @include font-gilroy-regular;
  @include set-breakpoint-fontsize($ch-font-p_small);
  line-height: 1.6;
  text-transform: lowercase;
  color: $ch-color-dark1;
}

.counter {
  @include font-gilroy-regular;
  font-size: 24px;
  line-height: 1.3;
  color: $ch-color-black;

  &.big {
    font-size: 28px;
  }
}
