@import "../../../utils";

.small,
.regular,
.big {
  margin: $space-s 0;
  line-height: $ch-lineheight-text;
}

.regular {
  @include set-breakpoint-fontsize($ch-font-p);
}
.big {
  @include set-breakpoint-fontsize($ch-font-p_big);
}
.small {
  @include set-breakpoint-fontsize($ch-font-p_small);
}
