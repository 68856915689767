@mixin mq($width, $type: min) {
  @if map_has_key($ch-breakpoints-screen, $width) {
    $width: map_get($ch-breakpoints-screen, $width);
    @if $type == max {
      $width: $width - 1px;
    }
    @media only screen and (#{$type}-width: $width) {
      @content;
    }
  }
}

@mixin container() {
  display: block;
  margin: 0 auto;

  width: 100%;
  @include mq(mobile, max) {
    width: calc(100% - #{$space-l * 2});
  }
  @include mq(mobile) {
    @include mq(desktop, max) {
      width: calc(100% - #{$space-xl * 2});
    }
    @include mq(tablet, max) {
      max-width: $ch-container-mobile;
    }
  }
  @include mq(tablet) {
    @include mq(desktop, max) {
      max-width: $ch-container-tablet;
    }
  }
  @include mq(desktop) {
    @include mq(desktop-large, max) {
      max-width: $ch-container-desktop;
    }
  }
  @include mq(desktop-large) {
    max-width: $ch-container-desktop-large;
  }
}

@mixin text-overflow() {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

@mixin pre-wrap {
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -pre-wrap;
  white-space: -o-pre-wrap;
  word-wrap: break-word;
}

@mixin set-breakpoint-fontsize($name) {
  font-size: map_get($name, "mobile");
  @include mq(tablet) {
    @include mq(desktop, max) {
      font-size: map_get($name, "tablet");
    }
  }
  @include mq(desktop) {
    font-size: map_get($name, "desktop");
  }
}
