/*
    styleguide 
        not applying these values by themselves
        using as library-reference
        assigning to $ch-color-... variables 
*/

$styleguide-shark: #383e45;
$styleguide-mountain-meadow: #1fc19b;
$styleguide-santas-gray: #a1a3ab;
$styleguide-athens-gray: #f2f2f7;
$styleguide-gallery: #ebebeb;
$styleguide-mid-gray: #646675;

// other
//    (not included in styleguide but are used in the design)
$ch-color-bg-body: #f4f4f8;
$ch-color-error: #fa531d;
$ch-color-orange: #fb764a;

/*
    colors grayscale. 
    if new added - adjust others accordingly

    1 - is whiter than 2
    // light1 is more white than light2 and so on
    // dark2 is more black than dark1
*/
$ch-color-light1: $styleguide-athens-gray;
$ch-color-light2: $styleguide-gallery;
$ch-color-light3: #d4d4d4;
$ch-color-light4: #abadb4;
$ch-color-dark1: $styleguide-mid-gray;
$ch-color-dark2: #898d8d;

// states
$ch-color-white: #ffffff;
$ch-color-white-hover: #cafff2;
$ch-color-white-pressed: $ch-color-white;

$ch-color-red: #cb4031;
$ch-color-red-hover: lighten($ch-color-red, 5%);
$ch-color-red-pressed: darken($ch-color-red, 5%);

$ch-color-blue: #3b62bf;
$ch-color-blue-hover: lighten($ch-color-blue, 5%);
$ch-color-blue-pressed: darken($ch-color-blue, 5%);

$ch-color-black: $styleguide-shark;
$ch-color-black-hover: lighten($ch-color-black, 5%);
$ch-color-black-pressed: darken($ch-color-black, 5%);

$ch-color-grey: #cccccc;
$ch-color-grey-hover: $styleguide-santas-gray;
$ch-color-grey-pressed: darken($ch-color-grey, 5%);

$ch-color-primary: $styleguide-mountain-meadow;
$ch-color-primary-hover: #1db692;
$ch-color-primary-pressed: #18a483;

//opacities
$ch-opacity-black-008: rgba($ch-color-black, 0.08);
$ch-opacity-black-015: rgba($ch-color-black, 0.15);
$ch-opacity-black-025: rgba($ch-color-black, 0.25);
$ch-opacity-white-02: rgba($ch-color-white, 0.2);
$ch-opacity-primary-05: rgba($ch-color-primary, 0.5);
$ch-opacity-primary-02: rgba($ch-color-primary, 0.2);
$ch-opacity-primary-01: rgba($ch-color-primary, 0.1);
// $ch-opacity-red-01: rgba($ch-color-red, 0.1);
$ch-opacity-orange-01: rgba($ch-color-orange, 0.1);

$ch-color-types-go-getter: #ed6128;
$ch-color-types-globe-trotter: #25b370;
$ch-color-types-ground-breaker: #fcc552;
$ch-color-types-change-maker: #635fb2;
$ch-color-types-balance-seeker: #6ab2e5;
