.root {
  top: 0;
  left: 0;
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
// SPINNERS STYLES
.spinner {
  // Common styles
  width: 4rem;
  height: 4rem;
  // Individual styles
  &.spinner3 {
    border-top: 3px solid rgba(black, 0.5);
    border-right: 3px solid transparent;
    border-radius: 50%;
    animation: rotation 0.8s linear infinite;
  }
}

// ANIMATIONS
@keyframes rotation {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
