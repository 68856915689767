@import "../../../utils";

.link {
  cursor: pointer;
  color: $ch-color-black;

  &:hover {
    color: $ch-color-blue;
  }
}
