// general
$ch-fontsize: 16px;

// lineheight
$ch-lineheight-text: 1.4;
$ch-lineheight-header: 1.1;

$ch-font-h1: (
  "desktop": 54px,
  "tablet": 45px,
  "mobile": 38px,
);
$ch-font-h2: (
  "desktop": 50px,
  "tablet": 40px,
  "mobile": 36px,
);
$ch-font-h3: (
  "desktop": 30px,
  "tablet": 28px,
  "mobile": 25px,
);
$ch-font-h4: (
  "desktop": 22px,
  "tablet": 22px,
  "mobile": 22px,
);

$ch-font-p_big: (
  "desktop": 20px,
  "tablet": 19px,
  "mobile": 18px,
);
$ch-font-p: (
  "desktop": 16px,
  "tablet": 16px,
  "mobile": 16px,
);
$ch-font-p_small: (
  "desktop": 0.875rem,
  "tablet": 0.875rem,
  "mobile": 0.875rem,
);

$ch-font-button: (
  "desktop": 20px,
  "tablet": 19px,
  "mobile": 18px,
);
$ch-font-button_big: (
  "desktop": 22px,
  "tablet": 20px,
  "mobile": 18px,
);
$ch-font-button_small: (
  "desktop": 1rem,
  "tablet": 1rem,
  "mobile": 1rem,
);
