* {
  box-sizing: border-box;
}

input {
  outline: 0 !important;
}

textarea:focus,
input:focus {
  outline: none;
}

:focus {
  outline: none;
}

button {
  -webkit-tap-highlight-color: transparent;
  &:hover,
  &:focus,
  &:active {
    -webkit-tap-highlight-color: transparent;
  }
}

html,
body {
  margin: 0;
  overflow-x: hidden;
  width: 100%;
  background-color: $ch-color-white;
}
